import React from "react";
import HomeResponsive from "../components/Home/HomeResponsive";
import { Box } from '@chakra-ui/react'



const HomePage = () => {

  
    return (
      <>
     <Box className="fade tipo">
       <HomeResponsive></HomeResponsive>
     </Box>
      </>
    );
  };
  export default HomePage;
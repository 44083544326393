import React from "react";
import ContactResponsive from "../components/Contacto/ContactResponsive";
import { Box } from '@chakra-ui/react'





const Contacto = () => {

    return (
      <>

     <Box
     className="fade tipo"
     >
        <ContactResponsive></ContactResponsive>
     </Box>
      </>
    );
  };
  export default Contacto;